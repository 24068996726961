import { registerApplication } from 'single-spa';
import { events } from '@fnni/globals';

import apps from '../apps';
import applicationCustomProps from '../applicationCustomProps';
import { oktaAuth } from '../authClient';

/**
 * @enum {string}
 */
const keys = {
    open: 'open',
    compliant: 'compliant',
    required: 'required',
};
const applicationMap = {
    [apps.auth]: keys.open,
    [apps.styleguide]: keys.open,
    [apps.nav]: keys.compliant,
    [apps.footer]: keys.compliant,
    [apps.interstitial]: keys.compliant,
    [apps.contact]: keys.compliant,
};

export const createPartition = (applicationList) => {
    const partitionObject = {
        [keys.open]: [],
        [keys.compliant]: [],
        [keys.required]: [],
    };

    for (let app of applicationList) {
        switch (applicationMap[app.name]) {
            case keys.open:
                partitionObject[keys.open].push(app);
                break;
            case keys.compliant:
                partitionObject[keys.compliant].push(app);
                break;
            default:
                partitionObject[keys.required].push(app);
                break;
        }
    }

    return partitionObject;
};

function boot(applications, customProps = {}) {
    applications.forEach((app) => {
        registerApplication({
            ...app,
            customProps: () => ({
                ...customProps,
                ...applicationCustomProps.getProps(app.name),
                rootEvents: events,
            }),
        });
    });
}

// Curry resolved from Promise.all
const register =
    ({ applications }) =>
    () => {
        // Creates a partition of apps.
        const { open, compliant, required } = createPartition(applications);

        const bootCompliant = ({ isAuthenticated }) => {
            if (isAuthenticated) {
                oktaAuth.authStateManager.unsubscribe(bootCompliant);
                boot(compliant);
            }
        };

        boot(open);

        const currentAuth =
            oktaAuth.authStateManager.getAuthState()?.isAuthenticated;

        bootCompliant({ isAuthenticated: currentAuth });

        if (!currentAuth) {
            oktaAuth.authStateManager.subscribe(bootCompliant);
        }

        events.subscribeOnceReady(({ ready }) => {
            /* istanbul ignore else */
            if (ready) {
                boot(required);
            }
        });
    };

export default register;
