const apps = {
    styleguide: '@fnni/styleguide',
    auth: '@fnni/auth',
    globals: '@fnni/globals',
    nav: '@fnni/nav',
    footer: '@fnni/footer',
    accounts: '@fnni/accounts',
    payments: '@fnni/payments',
    profileSettings: '@fnbo-ods/mfe-preferences',
    balanceTransfer: '@fnni/balance-transfer',
    contact: '@fnni/contact',
    interstitial: '@fnni/interstitial',
    charts: '@fnni/charts',
    transactions: '@fnni/transactions',
    components: '@fnni/components',
    componentLab: '@fnni/component-lab',
};

export default apps;
