import { start as startSingleSpa } from 'single-spa';

import register from '../register';

import apps from '../apps';

const start = async (config) => {
    await Promise.all([
        // Front loads the styleguide app
        System.import(apps.styleguide),
        // Client side authentication
        System.import(apps.auth),
        // Front loads the nav app
        System.import(apps.nav),
        // Front loads the footer app
        System.import(apps.footer),
    ])
        .then(register(config))
        .catch(console.log); // eslint-disable-line no-console

    // Starts up the app outside of promise.
    startSingleSpa({
        urlRerouteOnly: true,
    });
};

export default start;
