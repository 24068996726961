import root from './app/root';
import { api } from '@fnni/globals';
import tokenInterceptor from './app/interceptors/token';
import responseInterceptor from './app/interceptors/response';
import skipToMain from './skip-to-main';

skipToMain();

api.interceptors.request.use(tokenInterceptor, (error) => {
    // error handler
    return Promise.reject(error);
});

api.interceptors.response.use(
    responseInterceptor.onFulfilled,
    responseInterceptor.onRejected,
);

root();
