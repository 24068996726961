import {
    constructApplications,
    constructLayoutEngine,
    constructRoutes,
} from 'single-spa-layout';

export default function construct(element) {
    const routes = constructRoutes(element);

    const applications = constructApplications({
        routes,
        loadApp: ({ name }) => System.import(name),
    });

    constructLayoutEngine({ routes, applications });

    return {
        routes,
        applications,
    };
}
