import { addErrorHandler, getAppStatus, LOAD_ERROR } from 'single-spa';

export default function logErrors() {
    /**
     * LOAD_ERROR status
     * You need to add the following code to get SystemJS to re-attempt the network request when your loading function
     *  calls System.import() on an application in LOAD_ERROR status.
     */
    addErrorHandler((err) => {
        // Also helpful that the error gets logged so you know why it failed.
        console.error(err);
        if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
            System.delete(System.resolve(err.appOrParcelName));
        }
    });
}
