export default function skipToMain() {
    /**
     * Handles click event on a link and scrolls to the target element.
     * @param {MouseEvent} event - The click event.
     */
    function handleClick(event) {
        event.preventDefault();

        const url = new URL(event.target.href);
        let target;

        if (url.hash) {
            target = document.querySelector(url.hash);
        }

        target?.scrollIntoView();
        target?.focus();
    }

    document.addEventListener('readystatechange', () => {
        const mainLink = document.querySelector('#MainContentLink');

        if (document.readyState === 'complete' && mainLink?.href) {
            mainLink.onclick = handleClick;
        }
    });
}
