import { oktaAuth } from '../authClient';

/**
 * @typedef {import('axios').AxiosRequestConfig} config
 */

/**
 * Adds the Authorization header with the Okta access token to the Axios config.
 * @param {config} config
 * @returns {config} config with Authorization header
 */
const addAuthorizationHeader = (config) => {
    const accessToken = oktaAuth.getAccessToken();

    return {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

/**
 * Interceptor to apply the Authorization header to API calls.
 * Will add the bearer token if the user is authenticated; and if not, will wait until they are.
 * @param {config} config
 * @returns {Promise<config>}
 */
function tokenInterceptor(config) {
    return new Promise((resolve) => {
        if (oktaAuth.getAccessToken()) {
            resolve(addAuthorizationHeader(config));
        } else {
            const manageInterceptor = () => {
                resolve(addAuthorizationHeader(config));
                oktaAuth.tokenManager.off('added', manageInterceptor);
            };
            oktaAuth.tokenManager.on('added', manageInterceptor);
        }
    });
}

export default tokenInterceptor;
