import { queryClient } from '@fnni/globals';

const HTTP_METHODS = ['PUT', 'POST', 'PATCH', 'DELETE'];

const responseInterceptor = {
    async onFulfilled(response) {
        /**
         * @type {string}
         */
        const method = response.config.method;
        if (HTTP_METHODS.includes(method.toUpperCase())) {
            /**
             * Invalidate all queries with "accounts"
             * - This needs to be done because something could
             * potentially modify account data in one of the methods.
             * - It should result in a full reset of cache.
             */
            try {
                await queryClient.invalidateQueries('accounts');
            } catch (error) {
                console.error(
                    `Failed to invalidate queries for response containing ${method}`,
                    error,
                    response,
                );
            }
        }

        return response;
    },
    onRejected: async (reason) => Promise.reject(reason),
};

export default responseInterceptor;
