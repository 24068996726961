const PARAM = 'entry';
const DEFAULT_BRAND = 'card';

export const uriMap = new Map([
    [DEFAULT_BRAND, 'https://env.card.fnbo.com/'],
    ['fsl', 'https://env.fnbolending.com/'],
    ['seiu', 'https://env.fnbolending.com/seiu'],
    ['nea', 'https://env.fnbolending.com/nea'],
    ['up', 'https://env.fnbolending.com/up'],
    ['firstbankcard', 'https://env.card.fnbo.com/'],
    ['jeep', 'https://env.card.fnbo.com/jeep'],
    ['scheels', 'https://env.card.fnbo.com/scheels'],
    ['mlife', 'https://env.card.fnbo.com/mgmrewards'],
    ['dodge', 'https://env.card.fnbo.com/dodge'],
    ['ram', 'https://env.card.fnbo.com/ram'],
    ['fiat', 'https://env.card.fnbo.com/fiat'],
    ['chrysler', 'https://env.card.fnbo.com/chrysler'],
    ['mopar', 'https://env.card.fnbo.com/mopar'],
    ['ford', 'https://env.card.fnbo.com/ford'],
    ['bp', 'https://env.card.fnbo.com/bp'],
    ['verizon', 'https://env.card.fnbo.com/verizon'],
    ['universal', 'https://env.card.fnbo.com/universal'],
    ['amtrakguestrewards', 'https://env.card.fnbo.com/amtrakguestrewards'],
    ['myperks', 'https://env.card.fnbo.com/myperks'],
    ['lincoln', 'https://env.card.fnbo.com/lincoln'],
]);

/**
 * Returns the post logout link for a given entry and wwwReplacement.
 * @param {string} entry - The entry for which the post logout link is generated.
 * @param {string} wwwReplacement - The replacement string for the 'www' part of the hostname.
 * @returns {string} - The generated post logout link.
 */
export function getPostLogoutLink(entry, wwwReplacement) {
    /**
     * Retrieves the post logout link based on the entry and wwwReplacement.
     * Defaults to "card" if the entry is not found.
     */
    const config = uriMap.get(entry) || uriMap.get(DEFAULT_BRAND);
    const url = new URL(config);
    url.hostname = `${wwwReplacement}${url.hostname.substring(
        url.hostname.indexOf('.'),
    )}`;

    return url.toString();
}

/**
 * Retrieves the post logout configuration based on the entry URL.
 * @param {string} entryUrl - The entry URL for the post logout configuration.
 * @returns {string} - The post logout link based on the cached entry and environment.
 */
export function postLogoutConfiguration(entryUrl) {
    const uri = new URL(entryUrl);
    const entry = uri.searchParams.get(PARAM);

    if (entry) {
        localStorage.setItem(PARAM, entry);
    }

    const cachedEntry = localStorage.getItem(PARAM);
    const [environment] = uri.hostname.split('.');

    return getPostLogoutLink(cachedEntry, environment);
}
