import { OktaAuth } from '@okta/okta-auth-js';
import { CALLBACK_PATH } from './constants';
import originConfig from './originConfig';

const storageTypes = ['sessionStorage', 'cookie'];
const { clientId, issuer, postLogoutRedirectUri } = originConfig(
    window.location.origin,
    window.location.href,
);

/**
 * @type {import('@okta/okta-auth-js').OktaAuth}
 */
const oktaAuth = new OktaAuth({
    clientId,
    issuer,
    redirectUri: new URL(CALLBACK_PATH, window.location.origin).href,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    postLogoutRedirectUri,
    /**
     * Required to support users which have custom timezones implemented in their system.
     * This is more common than you'd think.
     */
    maxClockSkew: 10800,
    cookies: {
        // set to false when running locally
        secure: !process.env.isLocal,
        sessionCookie: !process.env.isLocal,
    },
    storageManager: {
        token: {
            storageTypes,
            autoRenew: true,
        },
        cache: {
            storageTypes,
        },
        transaction: {
            storageTypes,
        },
    },
});

export default oktaAuth;
