import apps from './apps';
import { CALLBACK_PATH, oktaAuth } from './authClient';
import tokenInterceptor from './interceptors/token';
import responseInterceptor from './interceptors/response';

const timeout = 10 * 60 * 1000;

const applicationCustomProps = {
    logoutProps: {
        async onLogout() {
            // https://github.com/okta/okta-auth-js#signout
            await oktaAuth.signOut(null);
        },
    },
    authProps: {
        authClient: oktaAuth,
        callbackUrl: CALLBACK_PATH,
        sessionTimeout: timeout,
        promptBeforeTimeout: 2 * 60 * 1000,
    },
    inteceptorProps: {
        interceptor: tokenInterceptor,
    },
    /**
     * Returns custom props for the application
     * @param {string} appName
     * @returns {object}
     */
    getProps(appName) {
        switch (appName) {
            case apps.profileSettings:
                return {
                    ...this.logoutProps,
                    interceptorFn: tokenInterceptor,
                    responseFn: responseInterceptor.onFulfilled,
                };
            case apps.interstitial:
            case apps.nav:
                return this.logoutProps;
            case apps.auth:
                return this.authProps;
            default:
                return {};
        }
    },
};

export default applicationCustomProps;
