import logErrors from '../logErrors';
import start from '../start';
import construct from '../construct';
import { oktaAuth } from '../authClient';

export default async function root() {
    // Sets up the mock service worker for debugging purposes.
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development' && localStorage.getItem('msw')) {
        const { worker } = require('../../mocks/browser');
        const { rest } = require('msw');
        // Allows us to access the service worker through a global variable.
        window.mockServiceWorker = worker;
        window.restMethods = rest;

        /**
         * Note: if you are running this locally, this will attempt to find the service worker
         * registered under the relative path of the server.
         */
        worker.start({
            onUnhandledRequest: 'bypass',
        });
    }

    // Constructs layout
    const { applications } = construct(document.querySelector('#site-layout'));

    // Logs out the errors.
    logErrors();

    // start auth
    await oktaAuth.start();

    // Starts the application.
    await start({ applications });
}
